.container.contact__container {
    width: 68%;
    display: grid;
    grid-template-columns: 30% 54% 4%;
    gap: 12%;
    align-items: center;
    justify-content: center;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}
.contact__option:hover {
    background: transparent;
    border-color: var(--color-bg-variant);
}
.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}
form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}
input::placeholder, textarea::placeholder {
    color: var(--color-light);
}

.scroll__up {
    font-weight: 500;
    font-size: 2.5rem;
}

@media screen and (max-width: 850px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .scroll__up {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }
}
header {
    height: 60vh;
    overflow: hidden; 
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    
    font-size: 1.5rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 2rem;
    border-radius: 4rem;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

.scroll__down {
    position: absolute;
    right: -2.3rem;
    transform: rotate(90deg);
    font-weight: 500;
    font-size: 1.2rem;
}

/* MEDIA QUERIES (SMALL DEVICES LIKE SMART PHONES) */
@media screen and (max-width: 600px) {
    .header__socials, 
    .scroll__down {
       display: none;
    }
}


